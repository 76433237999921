import Ban from 'assets/icons/ban.svg';
import CheckCircle from 'assets/icons/check-circle.svg';
import CheckCirclePurple from 'assets/icons/check-circle-purple.svg';
import CheckCircleEmpty from 'assets/icons/check-circle-empty.svg';
import ClockSchedule from 'assets/icons/clock-schedule.svg';
import CrossIconWhite from 'assets/icons/cross-white.svg';
import Share from './icons/share.svg';
import CalendarBlank from './icons/calendar-blank.svg';
import ClockAfternoon from './icons/clock-afternoon.svg';
import Collection from './icons/collection.svg';
import Programs from './icons/programs.svg';
import Drop from './icons/drop.svg';
import Dumbbell from './icons/dumbbell.svg';
import EnvelopeSimple from './icons/envelope-simple.svg';
import MapPin from './icons/map-pin.svg';
import Parchment from './icons/parchment.svg';
import Star from './icons/star.svg';
import Bookmark from './icons/bookmark.svg';
import BackArrow from './icons/back-arrow.svg';
import Chevron from './icons/chevron.svg';
import ChevronRight from './icons/chevron-right.svg';
import ChevronGray from './icons/chevron--gray.svg';
import Phone from './icons/phone.svg';
import Droplets from './icons/droplets.svg';
import Sad from 'assets/icons/sad.svg';
import GoggleIcon from 'assets/icons/google.svg';
import OutlookIcon from 'assets/icons/outlook.svg';
import AppleIcon from 'assets/icons/apple.svg';
import YahooIcon from 'assets/icons/yahoo.svg';

import PunchingBag from './icons/punching-bag.svg';

import CrossIcon from 'assets/icons/cross.svg';
import EyeOff from 'assets/icons/eye-off.svg';
import Eye from 'assets/icons/eye.svg';
import Info from 'assets/icons/info.svg';
import LeaveLivestream from 'assets/icons/leave-live-stream.svg';
import ListWhite from 'assets/icons/list-white.svg';
import List from 'assets/icons/list.svg';
import Logout from 'assets/icons/logout.svg';
import MapWhite from 'assets/icons/map-white.svg';
import Map from 'assets/icons/map.svg';
import PlaySolid from 'assets/icons/play-solid.svg';
import PlaylistIcon from 'assets/icons/playlist.svg';
import PlaylistBlackIcon from 'assets/icons/playlist-black.svg';
import PlayRadio from 'assets/icons/play-radio.svg';
import Volume from 'assets/icons/volume.svg';
import PauseRadio from 'assets/icons/pause.svg';
import Music from 'assets/icons/music.svg';
import MusicActive from 'assets/icons/music-active.svg';
import Target from 'assets/icons/target.svg';
import Clock4 from 'assets/icons/clock-4.svg';
import Thunder from 'assets/icons/thunder.svg';
import Arrow from 'assets/icons/arrow.svg';
import Participants from 'assets/icons/participants.svg';
import ParticipantsActive from 'assets/icons/participants-active.svg';
import BenefitCalendar from 'assets/icons/benefit-calendar.svg';
import MonitorPlay from 'assets/icons/monitor-play.svg';
import OfficeBuilding from 'assets/icons/office-building.svg';
import BenefitWoman from 'assets/icons/benefit-woman.svg';
import EditPencil from 'assets/icons/edit-pencil.svg';
import EditPencilSimple from 'assets/icons/edit-pencil-simple.svg';

// equipment icons
import Ict from './icons/equipments/ict.svg';
import Venom from './icons/equipments/venom.svg';
import Kettlebells from './icons/equipments/kettlebells.svg';
import Kettlebell from './icons/equipments/kettlebells.svg';
import Viper from './icons/equipments/viper.svg';
import Hypervolt from './icons/equipments/hypervolt.svg';
import Hypersphere from './icons/equipments/hypersphere.svg';
import Weights from './icons/equipments/weights.svg';
import Reformer from './icons/equipments/reformer.svg';
import Gloves from './icons/equipments/gloves.svg';
import Tube from './icons/equipments/tube.svg';
import StationaryBike from './icons/equipments/stationary-bike.svg';
import Trx from './icons/equipments/trx.svg';
import Treadmill from './icons/equipments/treadmill.svg';
import Towel from './icons/equipments/towel.svg';
import Therabans from './icons/equipments/therabans.svg';
import StretchStrap from './icons/equipments/stretch-strap.svg';
import Strap from './icons/equipments/strap.svg';
import StabilityBall from './icons/equipments/stability-ball.svg';
import Sphere from './icons/equipments/sphere.svg';
import Rower from './icons/equipments/rower.svg';
import RowingMachine from './icons/equipments/rower.svg';
import PilatesBox from './icons/equipments/pilates-box.svg';
import PilatesBall from './icons/equipments/pilates-ball.svg';
import None from './icons/equipments/no-equipment.svg';
import MedicineBall from './icons/equipments/medicine-ball.svg';
import NoEquipment from './icons/equipments/no-equipment.svg';
import MagicCircle from './icons/equipments/magic-circle.svg';
import Gliders from './icons/equipments/gliders.svg';
import FoamRoller from './icons/equipments/foam-roller.svg';
import Mat from './icons/equipments/mat.svg';
import GluteBands from './icons/equipments/glute-bands.svg';
import Dumbbells from './icons/equipments/dumbbells.svg';
import DoubleTube from './icons/equipments/double-tube.svg';
import DoorBarre from './icons/equipments/door-barre.svg';
import Chair from './icons/equipments/chair.svg';
import Box from './icons/equipments/box.svg';
import BosuBall from './icons/equipments/bosu-ball.svg';
import Bolster from './icons/equipments/bolster.svg';
import Blocks from './icons/equipments/yoga-blocks.svg';
import Bike from './icons/equipments/stationary-bike.svg';
import Bench from './icons/equipments/bench.svg';
import Barre from './icons/equipments/barre.svg';
import Bands from './icons/equipments/bands.svg';
import Ball from './icons/equipments/stability-ball.svg';

// component icons
import CustomCrossIcon from 'components/common/icons/CustomCrossIcon';
import FilterIcon from 'components/common/icons/FilterIcon';
import CalendarIcon from 'components/common/icons/CalendarIcon';
import BackArrowBlack from './icons/back-arrow-black.svg';
import PlusCircle from 'assets/icons/plus-circle.svg';

// brand icon
import AktIcon from './icons/brands/icon/akt-icon.svg';
import BftIcon from './icons/brands/icon/bft-icon.svg';
import ClubPilatesIcon from './icons/brands/icon/club-pilates-icon.svg';
import CycleBarIcon from './icons/brands/icon/cycle-bar-icon.svg';
import GoIconWhite from './icons/brands/icon/go-icon-white.svg';
import GoIcon from './icons/brands/icon/go-icon.svg';
import LindoraIcon from './icons/brands/icon/lindora-icon.svg';
import PureBarreIcon from './icons/brands/icon/pure-barre-icon.svg';
import RowHouseIcon from './icons/brands/icon/row-house-icon.svg';
import RumbleIcon from './icons/brands/icon/rumble-icon.svg';
import StretchLabIcon from './icons/brands/icon/stretch-lab-icon.svg';
import StrideIcon from './icons/brands/icon/stride-icon.svg';
import YogaSixIcon from './icons/brands/icon/yoga-six-icon.svg';

// brand full logo
import AllFullLogo from './icons/brands/logo/all-full-logos.svg';
import AktFullLogo from './icons/brands/logo/akt-full-logo.svg';
import AktLogo from './icons/brands/logo/akt.svg';
import BftLogo from './icons/brands/logo/bft-logo.svg';
import ClubPilatesFullLogo from './icons/brands/logo/club-pilates-full-logo.svg';
import ClubPilatesLogo from './icons/brands/logo/clubpilates.svg';
import CycleBarLogo from './icons/brands/logo/cyclebar.svg';
import GoLogoBlack from './icons/brands/logo/go-black.svg';
import GoBlueLogo from './icons/brands/logo/go-blue.svg';
import GoFullLogo from './icons/brands/logo/go-full-logo.svg';
import GoLogo from './icons/brands/logo/go.svg';
import LindoraLogo from './icons/brands/logo/lindora.svg';
import PureBarreLogo from './icons/brands/logo/purebarre.svg';
import RowHouseFullLogo from './icons/brands/logo/row-house-full-logo.svg';
import RowHouseLogo from './icons/brands/logo/rowhouse.svg';
import RumbleFullLogo from './icons/brands/logo/rumble-full-logo.svg';
import RumbleLogo from './icons/brands/logo/rumble.svg';
import StretchLabLogo from './icons/brands/logo/stretchlab.svg';
import StrideFullLogo from './icons/brands/logo/stride-full-logo.svg';
import StrideLogo from './icons/brands/logo/stride.svg';
import XLogo from './icons/brands/logo/x-logo.svg';
import SecondaryXLogo from './icons/brands/logo/secondary-x-logo.svg';
import YogaSixLogo from './icons/brands/logo/yogasix.svg';

// brand logo
import AktSquareLogo from './icons/brands/square-logo/akt-square-logo.svg';
import BftSquareLogo from './icons/brands/square-logo/bft-square-logo.svg';
import ClubPilatesSquareLogo from './icons/brands/square-logo/club-pilates-square-logo.svg';
import CycleBarSquareLogo from './icons/brands/square-logo/cycle-bar-square-logo.svg';
import LindoraSquareLogo from './icons/brands/square-logo/lindora-square-logo.svg';
import PureBarreSquareLogo from './icons/brands/square-logo/pure-barre-square-logo.svg';
import RowHouseSquareLogo from './icons/brands/square-logo/row-house-square-logo.svg';
import RumbleSquareLogo from './icons/brands/square-logo/rumble-square-logo.svg';
import StretchLabSquareLogo from './icons/brands/square-logo/stretch-lab-square-logo.svg';
import StrideSquareLogo from './icons/brands/square-logo/stride-square-logo.svg';
import YogaSquareLogo from './icons/brands/square-logo/yoga-six-square-logo.svg';

// brand square logo
import WhiteAktLogo from './icons/brands/whitelogo/akt.svg';
import WhiteClubPilatesLogo from './icons/brands/whitelogo/clubpilates.svg';
import WhiteCycleBarLogo from './icons/brands/whitelogo/cyclebar.svg';
import WhiteLindoraLogo from './icons/brands/whitelogo/lindora.svg';
import WhitePureBarreLogo from './icons/brands/whitelogo/purebarre.svg';
import WhiteRowHouseLogo from './icons/brands/whitelogo/rowhouse.svg';
import WhiteRumbleLogo from './icons/brands/whitelogo/rumble.svg';
import WhiteStretchLabLogo from './icons/brands/whitelogo/stretchlab.svg';
import WhiteStrideLogo from './icons/brands/whitelogo/stride.svg';
import WhiteBftLogo from './icons/brands/whitelogo/bft.svg';
// White brand logo
import WhiteYogaSixLogo from './icons/brands/whitelogo/yogasix.svg';
import Calendar from './icons/calendar.svg';
// categories
import BarreIcon from './icons/categories/barre.svg';
import BoxingIcon from './icons/categories/boxing.svg';
import CyclingIcon from './icons/categories/cycling.svg';
import DanceIcon from './icons/categories/dance.svg';
import PilatesIcon from './icons/categories/pilates.svg';
import RowingIcon from './icons/categories/rowing.svg';
import RunningIcon from './icons/categories/running.svg';
import StrechingIcon from './icons/categories/streching.svg';
import YogaIcon from './icons/categories/yoga.svg';
import HiitIcon from './icons/categories/hiit.svg';
import AaptivIcon from './icons/categories/aaptiv.svg';
import HypericeIcon from './icons/categories/hyperice.svg';
import StrengthIcon from './icons/categories/strength.svg';
import OtherIcon from './icons/categories/other.svg';
import CheckMarkGreen from './icons/checkmark-green.svg';
import GhostIcon from './icons/ghost.svg';
import Completed from './icons/completed.svg';
import WellnessIcon from './icons/categories/wellness.svg';
// Activities
import Broadcast from './icons/activities/broadcast.svg';
import CheckCircleBlue from './icons/activities/check-circle.svg';
import ChevronRightWhite from './icons/activities/chevron-right-white.svg';
import Fire from './icons/activities/fire.svg';
import Heartbeat from './icons/activities/heartbeat.svg';
import MonitorPlayBlue from './icons/activities/monitor-play.svg';
import SmartWatch from './icons/activities/smartwatch.svg';
import InStudio from 'assets/icons/in-studio.svg';

const SVGIcons = {
  BenefitCalendar,
  MonitorPlay,
  OfficeBuilding,
  BenefitWoman,
  GoggleIcon,
  OutlookIcon,
  AppleIcon,
  YahooIcon,
  CalendarIcon,
  Completed,
  Arrow,
  Participants,
  ParticipantsActive,
  Music,
  MusicActive,
  PlaylistBlackIcon,
  PauseRadio,
  Volume,
  PlayRadio,
  PlaylistIcon,
  Map,
  MapWhite,
  List,
  ListWhite,
  LeaveLivestream,
  Sad,
  Info,
  Ban,
  CheckCircle,
  CheckCirclePurple,
  CheckCircleEmpty,
  EyeOff,
  Eye,
  PlaySolid,
  ClockSchedule,
  CrossIcon,
  CrossIconWhite,
  CustomCrossIcon,
  FilterIcon,
  PunchingBag,
  Mat,
  Droplets,
  Share,
  CalendarBlank,
  ClockAfternoon,
  Collection,
  Programs,
  Drop,
  Dumbbell,
  EnvelopeSimple,
  MapPin,
  Parchment,
  Star,
  Bookmark,
  BackArrow,
  BackArrowBlack,
  Chevron,
  ChevronGray,
  ChevronRight,
  Phone,
  PlusCircle,
  GhostIcon,
  Calendar,
  Target,
  Clock4,
  Thunder,
  EditPencil,
  EditPencilSimple,
  brandsLogo: {
    YogaSixLogo,
    CycleBarLogo: CycleBarLogo,
    AktLogo,
    ClubPilatesLogo,
    PureBarreLogo,
    RowHouseLogo,
    RumbleLogo,
    StretchLabLogo,
    StrideLogo,
    BftLogo,
    LindoraLogo,
  },
  brandsLogoWhite: {
    WhiteYogaSixLogo,
    WhiteCycleBarLogo,
    WhiteAktLogo,
    WhiteClubPilatesLogo,
    WhitePureBarreLogo,
    WhiteRowHouseLogo,
    WhiteRumbleLogo,
    WhiteStretchLabLogo,
    WhiteStrideLogo,
    WhiteBftLogo,
    WhiteLindoraLogo,
  },
  brandsSquareLogo: {
    YogaSquareLogo,
    CycleBarSquareLogo,
    AktSquareLogo,
    ClubPilatesSquareLogo,
    PureBarreSquareLogo,
    RowHouseSquareLogo,
    RumbleSquareLogo,
    StretchLabSquareLogo,
    StrideSquareLogo,
    BftSquareLogo,
    LindoraSquareLogo,
  },
  Logout,
  CheckMarkGreen,
  GoIcon,
  GoIconWhite,
  brands: {
    YogaSixLogo,
    CycleBarLogo,
    AktLogo,
    ClubPilatesLogo,
    LindoraLogo,
    PureBarreLogo,
    RowHouseLogo,
    RumbleLogo,
    StretchLabLogo,
    StrideLogo,
    AktFullLogo,
    ClubPilatesFullLogo,
    RowHouseFullLogo,
    GoFullLogo,
    GoBlueLogo,
    XLogo,
    SecondaryXLogo,
    RumbleFullLogo,
    StrideFullLogo,
    AllFullLogo,
    GoLogo,
    GoLogoBlack,
  },
  brandIcon: {
    YogaSixIcon,
    CycleBarIcon,
    AktIcon,
    ClubPilatesIcon,
    LindoraIcon,
    PureBarreIcon,
    RowHouseIcon,
    RumbleIcon,
    StretchLabIcon,
    StrideIcon,
    GoIcon,
    BftIcon,
  },
  categories: {
    BarreIcon,
    BoxingIcon,
    CyclingIcon,
    DanceIcon,
    PilatesIcon,
    RowingIcon,
    RunningIcon,
    StrechingIcon,
    YogaIcon,
    HiitIcon,
    AaptivIcon,
    HypericeIcon,
    StrengthIcon,
    WellnessIcon,
    OtherIcon,
  },
  equipments: {
    Ict,
    Venom,
    Viper,
    Hypervolt,
    Hypersphere,
    Weights,
    Tube,
    Trx,
    Towel,
    Therabans,
    StretchStrap,
    Strap,
    StabilityBall,
    Sphere,
    Kettlebells,
    Kettlebell,
    Rower,
    PilatesBox,
    PilatesBall,
    None,
    Reformer,
    MedicineBall,
    NoEquipment,
    MagicCircle,
    Gliders,
    FoamRoller,
    Mat,
    Dumbbells,
    DoubleTube,
    DoorBarre,
    Gloves,
    Chair,
    Box,
    BosuBall,
    Bolster,
    Blocks,
    Bike,
    Bench,
    Barre,
    Bands,
    Ball,
    RowingMachine,
    Treadmill,
    StationaryBike,
    GluteBands,
  },
  activities: {
    Broadcast,
    CheckCircleBlue,
    ChevronRightWhite,
    Fire,
    Heartbeat,
    MonitorPlayBlue,
    SmartWatch,
    InStudio,
  },
};

export default SVGIcons;
